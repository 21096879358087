import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { formatCurrency } from '@quno/patient-journey/src/utils/currency';
import { CURRENCY } from '@quno/patient-journey/src/constants/payment';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { Card } from '@quno/patient-journey/src/components/Card/Card';
import styles from '@quno/patient-journey/src/components/Card/Variants/TreatmentCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type TreatmentCardProps = {
  cid: string;
  image?: JSX.Element | null;
  name?: string | null;
  duration?: number | null;
  description?: string | null;
  price?: number | null;
  isFrom?: boolean | null;
  footer?: string | null;
  cta?: JSX.Element | null;
};

export const TreatmentCard = ({
  cid,
  image,
  name,
  footer,
  description,
  price,
  duration,
  isFrom,
  cta,
  fontColorOverride,
  backgroundColorOverwrite,
  cardBorderRadius,
}: CardProps & TreatmentCardProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const { locale, t } = useTranslation(true);

  return (
    <Card
      className={styles.item}
      footer={footer}
      fontColorOverride={fontColorOverride}
      backgroundColorOverwrite={backgroundColorOverwrite}
      style={{ borderRadius: cardBorderRadius || 0 }}
    >
      <div
        className={styles.imageWrapper}
        {...inspectorProps({ fieldId: 'package' })}
      >
        {image}
      </div>
      <div>
        <h4
          className={styles.title}
          {...inspectorProps({ fieldId: 'package' })}
          style={{
            color: fontColorOverride,
          }}
        >
          {name}
        </h4>
        {duration && (
          <div className={styles.duration}>
            <span
              {...inspectorProps({ fieldId: 'package' })}
            >{`${duration} min`}</span>
          </div>
        )}
      </div>
      <div
        className={styles.description}
        {...inspectorProps({ fieldId: 'package' })}
      >
        {description}
      </div>
      <div className={styles.footer}>
        <p className={styles.price} {...inspectorProps({ fieldId: 'package' })}>
          {isFrom && (
            <>
              <span>{t('from')}</span>{' '}
            </>
          )}
          <span>{formatCurrency(price || 0, CURRENCY, locale)}</span>
        </p>
        <div {...inspectorProps({ fieldId: 'cta' })}>{cta}</div>
      </div>
    </Card>
  );
};
