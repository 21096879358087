import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { AccordionItem } from '@quno/patient-journey/src/components/Accordion/AccordionItem';
import type { BlockAccordionItemFragment } from '../../generated/graphql-patient-journey';

type BlockAccordionItemProps = BlockAccordionItemFragment & {
  fontColorOverride?: string;
  backgroundColorOverwrite?: string;
};

export const BlockAccordionItem = (
  props: BlockAccordionItemProps,
): JSX.Element => {
  const updatedEntity = useContentfulLiveUpdates(props);

  const { content, title, fontColorOverride, backgroundColorOverwrite } =
    updatedEntity;

  return (
    <AccordionItem
      cid={props.sys.id}
      title={title || ''}
      // eslint-disable-next-line
      // @ts-ignore
      content={content}
      fontColorOverride={fontColorOverride}
      backgroundColorOverwrite={backgroundColorOverwrite}
    />
  );
};
