import { useState } from 'react';
import clsx from 'clsx';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Card } from '@quno/patient-journey/src/components/Card/Card';
import styles from './BeforeAfterPhotoCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type BeforeAfterPhotoCardProps = {
  cid: string;
  beforePhoto: JSX.Element | null;
  afterPhoto: JSX.Element | null;
};

export const BeforeAfterPhotoCard = ({
  cid,
  beforePhoto,
  afterPhoto,
  backgroundColorOverwrite,
  darkModeEnabled,
  cardBorderRadius,
}: CardProps & BeforeAfterPhotoCardProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });

  const [isAfterPhotoShown, setIsAfterPhotoShown] = useState(true);

  const switchImage = (): void => {
    setIsAfterPhotoShown(!isAfterPhotoShown);
  };

  return (
    <Card
      className={styles.item}
      full={false}
      image={
        <>
          <span className={styles.image}>{beforePhoto}</span>
          <span
            className={clsx(styles.image, !isAfterPhotoShown && styles.hidden)}
          >
            {afterPhoto}
          </span>
        </>
      }
      contentAlignment="bottom right"
      mediaContentClassName={styles.media}
      mediaChildren={
        <label
          className={styles.switchLabel}
          {...inspectorProps({
            fieldId: isAfterPhotoShown ? 'secondaryImage' : 'primaryImage',
          })}
        >
          <span className={styles.switch}>
            <input
              type="checkbox"
              checked={isAfterPhotoShown}
              onChange={switchImage}
            />
            <span className={styles.slider} />
          </span>
        </label>
      }
      backgroundColorOverwrite={backgroundColorOverwrite}
      darkModeEnabled={darkModeEnabled}
      style={{ borderRadius: cardBorderRadius || 0 }}
    />
  );
};
