import { clsx } from 'clsx';
import { useContext } from 'react';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import { textColorClass } from '@quno/patient-journey/src/theme/textColorClass';
import { ModalContext } from '@quno/patient-journey/src/context/ModalContext';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import ExternalFunnelButton from '../shared/ExternalFunnel/ExternalFunnelButton';
import { FunnelCalendarBooking } from '../CalendarBooking/FunnelCalendarBooking';
import styles from './Cta.module.scss';
import type { Package } from '@quno/patient-journey/src/contentful/patient-journey/blocks/BlockCard';

export type CtaProps = {
  text?: string | null;
  variant?: string | null;
  action?: string | null;
  link?: string | null;
  backgroundColor?: string | null;
  fontColor?: string | null;
  backgroundColorOverwrite?: string | null;
  darkModeEnabled?: boolean;
  funnelId?: string | null;
  eventSetupId?: string | null;
  contentfulPackage?: Package;
};

export const Cta = ({
  action = 'link',
  link,
  text,
  backgroundColor = 'default',
  backgroundColorOverwrite,
  fontColor,
  variant = 'primary',
  darkModeEnabled,
  funnelId,
  eventSetupId,
  contentfulPackage,
}: CtaProps): JSX.Element | null => {
  const { setModal } = useContext(ModalContext);
  const brand = useBrand();
  const buttonClass = clsx(
    styles.button,
    variant === 'secondary' && [
      styles.buttonSecondary,
      textColorClass(darkModeEnabled),
      'text- underline',
    ],
    variant === 'primary' && backgroundColorClass(backgroundColor),
  );

  if (action === 'link') {
    return (
      <a
        className={buttonClass}
        href={link!}
        title={text!}
        style={{
          backgroundColor: backgroundColorOverwrite || undefined,
          color: fontColor || undefined,
        }}
      >
        {text}
      </a>
    );
  }

  if (action === 'funnel' && funnelId) {
    return (
      <ExternalFunnelButton
        funnelId={funnelId}
        forceFullScreen
        configFromSameDomain
        behavior="fullscreen"
        renderButton={(openFunnel) => {
          return (
            <button
              className={buttonClass}
              style={{
                backgroundColor: backgroundColorOverwrite || undefined,
                color: fontColor || undefined,
              }}
              title={text!}
              onClick={() => {
                openFunnel();
              }}
            >
              {text}
            </button>
          );
        }}
        customLogoOverride={brand?.logo}
      />
    );
  }

  if (action === 'calendar') {
    return (
      <button
        className={buttonClass}
        style={{
          backgroundColor: backgroundColorOverwrite || undefined,
          color: fontColor || undefined,
        }}
        title={text!}
        onClick={() => {
          setModal({
            isOpen: true,
            content: (
              <FunnelCalendarBooking
                eventSetupId={eventSetupId!}
                funnelId={funnelId!}
                contentfulPackage={contentfulPackage}
              />
            ),
            showCloseButton: false,
            showPrevIcon: false,
            fullScreen: true,
          });
        }}
      >
        {text}
      </button>
    );
  }

  return null;
};
